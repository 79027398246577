<template>
  <component
    :is="tag"
    v-if="!shad"
    class="btn"
    :class="{
      'is-loading': isLoading,
      'has-icon': $slots.icon,
      ...computedClasses,
    }"
    :disabled="isLoading"
    type="button"
    v-bind="$attrs"
  >
    <FontAwesomeIcon
      v-if="$props.icon && !iconAfter"
      :icon="$props.icon"
      v-bind="$props.fontAwesomeIconProps"
      class="icon"
      :class="{ 'icon-before': !iconAfter, 'icon-middle': !hasIconMiddle, '!mr-0': noText }"
    />
    <slot name="default" />
    <FontAwesomeIcon
      v-if="$props.icon && iconAfter"
      :icon="$props.icon"
      class="icon"
      v-bind="$props.fontAwesomeIconProps"
      :class="{ 'icon-after': iconAfter, '!ml-0': noText }"
    />
  </component>
  <BaseTooltip v-if="shad" class="h-full w-full" :content="tooltipContent">
    <ButtonComponent v-bind="$attrs" :variant="props.variant">
      <FontAwesomeIcon v-if="props.variant === 'tableRowEdit'" icon="fa-regular fa-pen" />
      <FontAwesomeIcon v-if="props.variant === 'tableRowDelete'" icon="fa-regular fa-trash" />
      <slot name="default" />
    </ButtonComponent>
  </BaseTooltip>
</template>

<script setup lang="ts">
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, getCurrentInstance } from 'vue';

import BaseTooltip from '@/components/tooltip/BaseTooltip.vue';
import { ButtonComponent } from '@/components/ui/button';
const sizes = ['small', 'medium', 'large'] as const;
const buttonVariants = [
  'primary',
  'secondary',
  'light',
  'success',
  'warning',
  'danger',
  'info',
  'link',
  'outlined',
  'none',

  // shad based variants
  'tableRowEdit',
  'tableRowDelete',
] as const;

const tags = ['button', 'a'] as const;
type ButtonVariant = (typeof buttonVariants)[number];
type ButtonSizes = (typeof sizes)[number];
type BtnClass = `${'btn-'}${ButtonSizes}`;
type BtnOutlineClass = `btn-${ButtonVariant}-outline` | 'btn-outline';

interface Props {
  readonly defaultValue: string;
  readonly isLoading: boolean;
  readonly icon: string | string[] | IconDefinition;
  readonly iconAfter: boolean;
  readonly tag: (typeof tags)[number];
  readonly variant: ButtonVariant;
  readonly outline: boolean;
  readonly fontAwesomeIconProps: typeof FontAwesomeIcon;
  readonly size: (typeof sizes)[number];
  readonly block: boolean;
  readonly noText: boolean;
}

const props = withDefaults(defineProps<Partial<Props>>(), {
  isLoading: false,
  iconAfter: false,
  tag: 'button',
  size: 'medium',
});

const instance = getCurrentInstance();

/**
 * when we want to render only an icon in the middle of the button
 * this will add a class icon-middle to reset the margins of the icon
 */
const hasIconMiddle = computed(
  () => instance && instance.slots.default && instance.slots.default().length > 0
);

const computedClasses = computed(() => {
  return {
    [variant(props.variant, props.outline)]: true,
    [outline(props.outline, props.variant)]: true,
    [size(props.size)]: true,
    [block(props.block)]: true,
  };
});

const size = (size: ButtonSizes = 'medium') =>
  ({
    [size]: `btn-${size}`,
  })[size] as BtnClass;

const block = (block: boolean = false) => (block ? 'btn-block' : '');

const outline = (outline: boolean = false, variant?: ButtonVariant) =>
  outline ? (`${variant ? `btn-${variant}-outline` : ''}` as BtnOutlineClass | '') : '';

const variant = (variant: ButtonVariant = 'primary', outline: boolean): string =>
  outline ? '' : `btn-${variant}`;

// shad
const shad = computed(() => ['tableRowEdit', 'tableRowDelete'].includes(props.variant ?? ''));

const variantTooltips: Record<string, string> = {
  tableRowEdit: 'Edit',
  tableRowDelete: 'Delete',
};

const tooltipContent = computed(() => variantTooltips[props.variant ?? '']);
</script>

<style scoped lang="scss">
@import 'src/assets/scss/components/_buttons.scss';

.btn {
  text-decoration: none;
  &-large {
    padding: var(--spacing-200);
  }
  &-block {
    flex: 1;
  }
  .icon-after {
    margin-left: 0;
    margin-right: 0;
    margin-left: var(--spacing-050);
  }
  .icon-before {
    margin-left: 0;
    margin-right: 0;
    margin-right: var(--spacing-050);
  }
  .icon-middle {
    margin: 0;
  }
}
</style>
